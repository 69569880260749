var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "team-list-panel" }, [
    _vm.total > 0
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "team-list" },
              _vm._l(_vm.records, function (item, i) {
                return _c("div", { key: i, staticClass: "team-container" }, [
                  _c(
                    "div",
                    { staticClass: "team" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "linkTitle", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.showDetail(item.id, true)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "approvalStatus",
                              class: {
                                gray: item.status === 4,
                                blue: item.status === 5,
                                red: item.status === 6,
                              },
                            },
                            [
                              _c("span", [_vm._v("审核情况：")]),
                              item.status === 4
                                ? _c("span", { staticClass: "gray" }, [
                                    _vm._v(" 正在审核中 "),
                                  ])
                                : _vm._e(),
                              item.status === 5
                                ? _c("span", { staticClass: "blue" }, [
                                    _vm._v(" 已通过 "),
                                  ])
                                : _vm._e(),
                              item.status === 6
                                ? _c("span", { staticClass: "red" }, [
                                    _vm._v(" 未通过 "),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "contentRow",
                          staticStyle: { "margin-top": "13px" },
                        },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("适体种类："),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(" " + _vm._s(item.type) + " "),
                          ]),
                        ]
                      ),
                      _c("el-row", { staticClass: "contentRow" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("靶标名称："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(" " + _vm._s(item.target) + " "),
                        ]),
                      ]),
                      _c("el-row", { staticClass: "contentRow timeRow" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("靶标种类："),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "value",
                            staticStyle: { "max-width": "570px" },
                          },
                          [_vm._v(" " + _vm._s(item.targetType) + " ")]
                        ),
                        _c("div", { staticClass: "uploadTime" }, [
                          _vm._v(
                            " 上传日期" +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  item.createOn,
                                  "YYYY-MM-DD"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "location location-bottom" }, [
                    item.status == 6
                      ? _c(
                          "div",
                          {
                            staticClass: "btn show-remark",
                            on: {
                              click: function ($event) {
                                return _vm.showRemark(item.auditRemark)
                              },
                            },
                          },
                          [_vm._v(" 查看反馈 ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "btn modify",
                        on: {
                          click: function ($event) {
                            return _vm.showDetail(item.id, false)
                          },
                        },
                      },
                      [_vm._v(" 修改序列 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn delete-item",
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]),
                ])
              }),
              0
            ),
            _vm.total > 0
              ? _c(
                  "div",
                  { staticClass: "pagination" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        id: "pagination",
                        "current-page": _vm.pager.index,
                        "page-size": _vm.pager.size,
                        "page-sizes": [10, 25, 50],
                        layout: "prev, pager, next, jumper, sizes",
                        total: _vm.total,
                      },
                      on: {
                        "current-change": _vm.queryChange,
                        "size-change": _vm.sizeChange,
                        "prev-click": _vm.queryChange,
                        "next-click": _vm.queryChange,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showConfirm
              ? _c(
                  "div",
                  { staticStyle: { width: "100%", height: "100%" } },
                  [
                    _c("ConfirmDialog", {
                      attrs: {
                        title: "序列删除",
                        describeText: "确认删除该序列",
                        imgPath: "delete.png",
                        cancelButtonText: "取消删除",
                        confirmButtonText: "确认删除",
                        confirmVal: _vm.confirmObj,
                      },
                      on: {
                        cancel: _vm.cancelDelete,
                        confirm: _vm.confirmDelete,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isShowDetail
              ? _c(
                  "div",
                  { staticClass: "to-login-panel" },
                  [
                    _c("SequenceSub", {
                      attrs: {
                        showDetail: 1 == 1,
                        showinfo: _vm.selectData,
                        canEdit: _vm.disableEdit,
                      },
                      on: { cancelsub: _vm.closeDialog },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-dialog",
              {
                staticClass: "audit-remark-dialog",
                attrs: {
                  title: "审核意见",
                  visible: _vm.isShowRemark,
                  width: "30%",
                  center: "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.isShowRemark = $event
                  },
                },
              },
              [
                _c("span", { staticClass: "audit-remark-message" }, [
                  _vm._v(_vm._s(_vm.remarkData)),
                ]),
              ]
            ),
          ],
          1
        )
      : _c("div", { staticClass: "blank" }, [_vm._v("暂未提交序列")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }