<template>
	<div class="team-list-panel">
		<div v-if="total > 0">
			<div class="team-list">
				<div class="team-container" v-for="(item, i) in records" :key="i">
					<div class="team">
						<el-row>
							<el-link
								type="linkTitle"
								:underline="false"
								@click="showDetail(item.id, true)"
								>{{ item.name }}</el-link
							>
							<div
								class="approvalStatus"
								:class="{
									gray: item.status === 4,
									blue: item.status === 5,
									red: item.status === 6
								}"
							>
								<span>审核情况：</span>
								<span class="gray" v-if="item.status === 4"> 正在审核中 </span>
								<span class="blue" v-if="item.status === 5"> 已通过 </span>
								<span class="red" v-if="item.status === 6"> 未通过 </span>
							</div>
						</el-row>
						<el-row class="contentRow" style="margin-top: 13px">
							<div class="label">适体种类：</div>
							<div class="value">
								{{ item.type }}
							</div>
						</el-row>
						<el-row class="contentRow">
							<div class="label">靶标名称：</div>
							<div class="value">
								{{ item.target }}
							</div>
						</el-row>
						<el-row class="contentRow timeRow">
							<div class="label">靶标种类：</div>
							<div class="value" style="max-width: 570px">
								{{ item.targetType }}
							</div>
							<div class="uploadTime">
								上传日期{{ item.createOn | dateFormat("YYYY-MM-DD") }}
							</div>
						</el-row>
					</div>
					<div class="location location-bottom">
						<div
							v-if="item.status == 6"
							class="btn show-remark"
							@click="showRemark(item.auditRemark)"
						>
							查看反馈
						</div>
						<div class="btn modify" @click="showDetail(item.id, false)">
							修改序列
						</div>
						<div class="btn delete-item" @click="deleteItem(item.id)">删除</div>
					</div>
				</div>
			</div>
			<div class="pagination" v-if="total > 0">
				<el-pagination
					id="pagination"
					:current-page="pager.index"
					:page-size="pager.size"
					:page-sizes="[10, 25, 50]"
					layout="prev, pager, next, jumper, sizes"
					:total="total"
					@current-change="queryChange"
					@size-change="sizeChange"
					@prev-click="queryChange"
					@next-click="queryChange"
				>
				</el-pagination>
			</div>
			<div v-if="showConfirm" style="width: 100%; height: 100%">
				<ConfirmDialog
					title="序列删除"
					describeText="确认删除该序列"
					imgPath="delete.png"
					cancelButtonText="取消删除"
					confirmButtonText="确认删除"
					:confirmVal="confirmObj"
					@cancel="cancelDelete"
					@confirm="confirmDelete"
				></ConfirmDialog>
			</div>
			<!--序列详情展示-->
			<div class="to-login-panel" v-if="isShowDetail">
				<SequenceSub
					:showDetail="1 == 1"
					:showinfo="selectData"
					:canEdit="disableEdit"
					@cancelsub="closeDialog"
				></SequenceSub>
			</div>
			<el-dialog
				title="审核意见"
				:visible.sync="isShowRemark"
				width="30%"
				center
				class="audit-remark-dialog"
			>
				<span class="audit-remark-message">{{ remarkData }}</span>
			</el-dialog>
		</div>
		<div v-else class="blank">暂未提交序列</div>
	</div>
</template>

<script>
import { ApiClient, SubmittedSequenceApi } from "../../../api"
import SequenceSub from "../../../components/SequenceSub.vue"
import ConfirmDialog from "../../../components/ContirmDialog.vue"
export default {
	data() {
		return {
			api: new SubmittedSequenceApi(ApiClient.instance),
			disableEdit: false,
			isShowDetail: false,
			showConfirm: false,
			confirmObj: null,
			openDialog: false,
			isShowRemark: false,
			selectData: {},
			orderBy: "",
			remarkData: {},
			records: [],
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			pager: {
				index: 1,
				size: 10
			}
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			var opts = { index: this.pageIndex, size: this.pageSize }
			this.api.apiSubmittedSequenceQueryGet(2, opts, (error, data, resp) => {
				if (error) {
					console.log(error)
				} else {
					this.records = data.result.records
					this.total = data.result.total
				}
			})
		},
		cancelDelete(val) {
			this.showConfirm = false
		},
		confirmDelete(val) {
			this.showConfirm = false
			this.api.apiSubmittedSequenceIdDelete(val, (err, data, resp) => {
				if (err) {
					console.log(err)
				}
				if (data && data.result) {
					this.$message({ type: "success", message: "序列删除成功！" })
				} else {
					this.$message({ type: "error", message: "序列删除失败" })
				}
				if (this.total - 1 > 0) {
					if (this.pageIndex > (this.total - 1) / this.pageSize) {
						this.pageIndex = Math.ceil((this.total - 1) / this.pageSize)
					}
				} else {
					this.pageIndex = 1
				}
				this.getData()
			})
		},
		// 分页条件改变
		queryChange(val) {
			this.pageIndex = val
			this.getData()
		},
		sizeChange(val) {
			this.pageIndex = 1
			this.pageSize = val
			this.getData()
		},
		closeDialog() {
			this.isShowDetail = false
		},
		showDetail(val, disable) {
			if (val) {
				var result = this.records.filter((item) => {
					if (item.id === val) {
						return item
					}
				})
				if (result.length > 0) {
					this.selectData = result[0]
					this.isShowDetail = true
					this.disableEdit = disable
				}
			}
		},
		showRemark(val) {
			this.remarkData = val
			this.isShowRemark = true
		},
		deleteItem(id) {
			this.confirmObj = id
			this.showConfirm = true
		}
	},
	components: {
		SequenceSub,
		ConfirmDialog
	}
}
</script>

<style lang="less" scoped>
.blank {
	text-align: center;
	margin-top: 200px;
}
/deep/ .el-link--inner {
	font-size: 17px;
	font-weight: 500;
	color: #3462e9;
	max-width: 640px;
	word-break: break-all;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; //行数需设置
	-webkit-box-orient: vertical;
	overflow: hidden;
}
</style>

<style lang="less" scoped>
.el-pagination {
	margin-top: 10px;
}

.top-bar-container {
	margin-top: 21px;
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.linkTitle {
	width: 630px;
	height: 24px;
	font-size: 17px;
	font-weight: 500;
	color: #3462e9;
}

.approvalStatus {
	text-align: right;
	width: 150px;
	height: 20px;
	font-size: 14px;
	font-weight: 400;
	float: right;
}

.contentRow {
	margin-top: 10px;
	display: flex;
	height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #333333;

	.label {
		width: 70px;
	}

	.value {
		word-break: break-all;
		max-width: 640px;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; //行数需设置
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.timeRow {
	position: relative;

	.uploadTime {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.top-bar {
	margin: 10px 20px;
}

.result {
	.c-font-size-12();
	.c-font-family-PF();
	line-height: 16px;
	color: #666666;
	display: flex;
	flex-direction: row;
}

.result .value {
	color: #3463ea;
	margin: 0 6px;
}

.team-list {
	width: 100%;
}

.team-container {
	width: 100%;
	background-color: #ffffff;
	margin-top: 16px;
}

.team {
	position: relative;
	// margin: 16px;
	padding: 15px 30px;
}

.important a {
	color: #3463e9;
	font-size: 17px !important;
	font-weight: 500;
}

.to-login-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;
}

.to-login-panel .login {
	margin: auto;
}

.location-bottom {
	width: 100%;
	height: 45px;
	background: #bbd4ff;
	padding: 10px 30px;
	display: flex;
	justify-content: right;

	.btn {
		width: 73px;
		height: 24px;
		opacity: 1;
		border-radius: 3px;
		margin-left: 5px;

		line-height: 24px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		cursor: pointer;
	}

	.show-remark {
		background: #6b77af;
	}

	.modify {
		background: #3462e9;
	}

	.delete-item {
		width: 47px;
		height: 24px;
		background: #ffffff;
		border: 1px solid #dbdbdb;
		opacity: 1;
		border-radius: 3px;

		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 24px;
		color: #666666;
	}
}

.audit-remark-dialog {
	font-size: 22px;
	font-family: PingFang SC;
	font-weight: bold;
	color: #333333;

	.audit-remark-message {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
	}
}

.gray {
	color: #aeaeae !important;
}

.blue {
	color: #3462e9 !important;
}

.red {
	color: #ff0000 !important;
}
</style>
